<template>
	<b-modal id="activate-company" :title="modalTitle" ok-title="Save" ref="modal" @hide="handleClose" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="confirm-message" v-if="isActive === 'true'">
			<span v-if="activeCompanyConnections.length > 0">
				<span>
					Are you sure you want to
					<b>deactivate</b>
					this company account and all of its active connections and users?
				</span>
				<b-row class="my-2">
					<b-col sm="5">
						<b>Active Connections:</b>
					</b-col>
					<b-col sm="4">{{ activeConnectionsDisplay }}</b-col>
				</b-row>
				<b-row class="my-2">
					<b-col sm="5">
						<b>Active Users:</b>
					</b-col>
					<b-col sm="4">{{ activeUsersDisplay }}</b-col>
				</b-row>
			</span>

			<span v-else>
				Are you sure you want to
				<b>deactivate</b> this company <b>"{{ name }}"</b>?
			</span>
		</div>

		<div class="confirm-message" v-else>
			Are you sure you want to
			<b>activate</b> this company <b>"{{ name }}"</b>?
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { LocationUtil } from '@/utils/locationUtil';

// DAO and API
import userDAO from '@/database/users';
import connectionDAO from '@/database/connections';
import companyApi from '@/api/companyApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import firebase from 'firebase/app';
import _ from 'lodash';

export default {
	name: 'activate-company',
	components: {
		Loading,
	},
	data() {
		return {
			selCompany: {},
			isActive: true,
			activeCompanyConnections: [],
			activeUsers: [],

			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		activeConnectionsDisplay: function () {
			let display = '';
			if (this.activeCompanyConnections.length === 0) {
				display = ' N/A ';
			} else {
				this.activeCompanyConnections.forEach((con) => {
					display += con.connectedCompany.name + ', ';
				});
				display = display.substring(0, display.length - 2);
			}
			return display;
		},
		activeUsersDisplay: function () {
			let display = '';
			if (this.activeUsers.length === 0) {
				display = ' N/A ';
			} else {
				this.activeUsers.forEach((user) => {
					display += user.emailAddress + ', ';
				});
				display = display.substring(0, display.length - 2);
			}
			return display;
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		name() {
			return this.selCompany.name;
		},
		modalTitle() {
			return this.isActive === 'true' ? 'Deactivate Company' : 'Activate Company';
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelCompany', async (selCompany) => {
			this.selCompany = selCompany;
			this.isActive = selCompany.isActive;

			await this.retrieveCompanyConnections();
			await this.retrieveUsers();
		});
	},
	methods: {
		async retrieveCompanyConnections() {
			this.activeCompanyConnections = [];

			let connectionsObj = await connectionDAO.getConnectionsByCompany(this.selCompany.id);
			if (connectionsObj && !_.isEmpty(connectionsObj)) {
				_.forEach(connectionsObj, con => {
					if (con.isActive === 'true') {
						this.activeCompanyConnections.push(con);
					}
				});
			}
		},
		async retrieveUsers() {
			let usersObj = await userDAO.getUsersByCompanyId(this.selCompany.id);

			this.activeUsers = _.filter(usersObj, (o) => {
				return o.isActive === 'true';
			});
		},
		toggleStatus(isActive) {
			let status = 'true';
			if (isActive === 'true') {
				status = 'false';
			}
			return status;
		},
		resetStatus() {
			this.selCompany.isActive = this.isActive;
			this.$store.commit('SET_CURR_COMPANY', this.selCompany);
		},
		handleClose() {
			this.resetStatus();
		},

		getCompanyObj(param) {
			// pre-process geoaddress
			if (!_.isEmpty(param.geoaddress)) {
				let latitude = LocationUtil.getLatitude(param.geoaddress);
				let longitude = LocationUtil.getLongitude(param.geoaddress);
				param.geoaddress = new firebase.firestore.GeoPoint(
					parseFloat(latitude),
					parseFloat(longitude)
				);
			}

			return {
				id: param.id,
				name: param.name,
				description: param.description,
				type: param.type,
				industry: param.industry,
				hasParentCompany: param.hasParentCompany
					? param.hasParentCompany
					: false,
				parentCompany: param.parentCompany ? param.parentCompany : '',
				parentCompanyId: param.parentCompanyId ? param.parentCompanyId : '',
				isActive: param.isActive,
				address: param.address,
				geoaddress: param.geoaddress,
				contactPerson: param.contactPerson,
				contactNo: param.contactNo,
				emailAddress: param.emailAddress,
				brand: param.brand,
				branchCode: param.branchCode,
				noOfStorageLocations: param.noOfStorageLocations
					? parseInt(param.noOfStorageLocations)
					: 0,
				noOfInactiveNodes: param.noOfInactiveNodes
					? parseInt(param.noOfInactiveNodes)
					: 0,
				noOfUsers: param.noOfUsers ? parseInt(param.noOfUsers) : 0,
				permissions: param.permissions,
				dateCreated: param.dateCreated,
				createdBy: param.createdBy,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
			};
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			// update the status
			this.selCompany.isActive = this.toggleStatus(this.isActive);

			try {
				let companyObj = this.getCompanyObj(this.selCompany);
				let { data } = await companyApi.saveCompany(
					companyObj,
					this.loggedUser.id,
					DateUtil.getCurrentTimestamp()
				);

				if (data.isSuccess) {
					let successMsg = '';
					if (data.company.isActive === 'true') {
						successMsg = `Company "${this.name}" account is now activated!`;
					} else {
						successMsg = `Company "${this.name}" account is now deactivated!`;
					}

					this.$toaster.success(successMsg);
					EventBus.$emit('onCloseEditCompany', data);
					this.$refs.modal.hide();
				} else {
					let errorMsg = '';
					if (this.toggleStatus(this.isActive) === 'true') {
						errorMsg = `There's a problem encountered during account activation of the company "${this.name}"`;
					} else {
						errorMsg = `There's a problem encountered during account deactivation of the company "${this.name}"`;
					}

					this.resetStatus();
					this.$toaster.error(errorMsg);
				}
			} catch (_error) {
				let errorMsg = '';
				if (this.toggleStatus(this.isActive) === 'true') {
					errorMsg = `There's a problem encountered during account activation of the company "${this.name}"`;
				} else {
					errorMsg = `There's a problem encountered during account deactivation of the company "${this.name}"`;
				}

				this.resetStatus();
				this.$toaster.error(errorMsg);
			}

			// hide loading indicator
			this.isLoading = false;
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelCompany');
	},
};
</script>
